import BugReportIcon from '@mui/icons-material/BugReport';
import Refresh from '@mui/icons-material/Refresh';
import { Box, Button, Stack, Typography } from '@mui/material';

import { supportEmailAddress } from '@/utils/config';

type ErrorProps = {
  error: Error | null;
  reset: () => void;
};

export default function ErrorComponent({ error, reset }: ErrorProps) {
  if (!error) return;
  return (
    <Box component={Stack} direction="column" justifyContent="center" alignItems="center" height={'100vh'} gap={2}>
      <Box>
        <BugReportIcon sx={{ width: 200, height: 200 }} />
      </Box>
      <Box>
        <Typography align="center">An error has occured.</Typography>
        <Typography align="center">
          If the error persists, reach out to <a href={'mailto:' + supportEmailAddress}>{supportEmailAddress}</a>.
        </Typography>
      </Box>
      <Box>
        <Button onClick={() => reset()} variant="contained" endIcon={<Refresh />}>
          Reload page
        </Button>
      </Box>
      <Box
        p={2}
        sx={{ maxWidth: '700px', backgroundColor: 'rgba(0, 0, 0, 0.05)', borderRadius: '16px', margin: '0 16px' }}
      >
        <Typography color={'error'} fontFamily={'monospace'}>
          {error.message}
        </Typography>
      </Box>
    </Box>
  );
}
